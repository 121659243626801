import { Request } from '@/components/authored-page-routes/authored-page-routes.interface';
import { type Locale } from '@/i18n/translations';
import { cookies } from 'Data/constants';
import Cookies from 'js-cookie';
import type { GetServerSidePropsContext, PreviewData } from 'next';
import type { ParsedUrlQuery } from 'querystring';
import {
  ACCESSIBILITY_KEYCODES,
  CERTONA_SCHEMES,
  Env,
  HOST,
  INTL_LOCALE,
  LOCALE,
  LoggedInStatus,
  MONTH_ABBREVIATONS,
  POWER_REVIEWS,
  RATINGS_MAP,
  UNIT_OF_MEASURMENT,
  VIEW_RESOLVER,
  banners,
  environment,
} from '../data/constants';
import { capitalizeFirstLetter } from './str-util';
import { IKamFlag } from '@/store/kameleoonFlags/kameleoonFlags.interface';

// TODO: Move this to a types file
// WARN: These represent the *current* shape of the data, which may change in the future
type FilterGroup = {
  name: string;
  code: string;
  filters: {
    name: '1 star and up' | `${'2' | '3' | '4'} stars and up` | '5 stars';
    count: number;
    queryParam: string;
    code: `${'one' | 'two' | 'three' | 'four'}StarUp` | 'fiveStar';
    isSelected: boolean;
    shadeIconUrl: string | null;
  }[];
  count: number;
};

type Badge = {
  name: string;
  uid: string;
  type: 'OFFER' | 'RIBBON';
};

type PowerReviewsConfig = {
  merchant_group_id: string;
  merchant_id: string;
  api_key: string;
};

// Overloads for debounce
export function debounce<T extends []>(
  callback: (...params: T) => void,
  delay: number,
  accumulate?: false
): (...params: T) => void;

export function debounce<T extends []>(
  callback: (...params: T[]) => void,
  delay: number,
  accumulate: true
): (...params: T) => void;

export function debounce<T extends []>(
  callback: (...params: T | T[]) => void,
  delay: number,
  accumulate = false
): (...params: T) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  let accumulatedParams: T[] = [];

  return (...parameters: T) => {
    if (accumulate) {
      accumulatedParams.push(parameters);
    }
    if (timeout) {
      clearTimeout(timeout);
    }

    // @ts-ignore
    const context = this;
    const args = accumulate ? accumulatedParams : parameters;
    timeout = setTimeout(() => {
      callback.apply(context, args);
      accumulatedParams = [];
      timeout = null;
    }, delay);
  };
}

/**
 * @param req - if a request object is provided, assume SSR and use request headers to grab hostname, otherwise use
 * window
 */
export const isPharmaprixBanner = (req?: Request): boolean => {
  if (req) {
    const host = req.headers.host;

    return host !== undefined && (host.includes(banners.PHARMAPRIX) || host.includes(banners.PHX));
  } else if (typeof window !== 'undefined') {
    const { hostname } = window.location;

    return hostname.includes(banners.PHARMAPRIX) || hostname.includes(banners.PHX);
  }

  return false;
};

export const getEnvironment = (): Env => {
  if (typeof window !== 'undefined') {
    const [subdomain] = window.location.hostname.split('.');

    if (subdomain.includes('local')) {
      return Env.Local;
    } else if (subdomain.includes('uat')) {
      return Env.Uat;
    } else if (subdomain.includes('dev')) {
      return Env.Dev;
    }
  }

  return Env.Prod;
};

export const getBannerHostUrl = (): string => {
  const isUatEnv = getEnvironment() === Env.Uat;
  const bannerType = isPharmaprixBanner() ? 'PHX' : 'SDM';
  const host = isUatEnv ? HOST[`${bannerType}_UAT`] : HOST[bannerType];
  return `https://${host}`;
};

export const getBannerHostUrlSSR = (hostname: string): string => {
  const isPharmaprix = hostname?.includes(banners.PHARMAPRIX) || hostname?.includes(banners.PHX);
  const host = isPharmaprix ? HOST.PHX : HOST.SDM;
  return `https://${host}`;
};

export const convertKamFlagsToBFFOpts = (activeKamFeatureFlags: Record<string, IKamFlag> | null): any =>
  Object.values(activeKamFeatureFlags || {})
    // flatten to get kam variables that contain feature flag names
    .map((exp: any) => exp.variables)
    .flat()
    .map((variable: any) => variable.value)
    .flat()
    // only look for variables values that have the featureFlag field
    .filter((val) => val.featureFlag && (val.status === true || val.status === 'true'))
    // map feature flag entries into object
    .map(({ featureFlag, value }) => ({ name: `bff.exp.${featureFlag}`, value: value || 'variant' }));

export const getLocaleSSR = (context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>): string => {
  const { query, req } = context;
  const hostname = req?.headers?.host || '';
  const isPharmaprix = hostname?.includes(banners.PHARMAPRIX) || hostname?.includes(banners.PHX);
  const defaultLocale = isPharmaprix ? LOCALE.FR : LOCALE.EN;
  const locale = (query?.lang as string) || req?.cookies?.user_preferred_locale || defaultLocale;

  return locale;
};

export const schemeLookup = ({
  page = 'HOME',
  landing = 'homeLandingPage',
  hasFavourites = 'noFavourites',
}: {
  page: keyof typeof pageLookup;
  landing: keyof typeof landingLookup;
  hasFavourites: keyof typeof favouritesLookup;
}): (typeof pageLookup)[typeof page] => {
  const landingLookup = {
    homeLandingPage: ['home6_rr', 'home7_rr', 'home8_rr'],
    beautyLandingPage: ['home3_rr', 'home4_rr', 'home5_rr'],
    personalcareLandingPage: ['pclanding1_rr', 'pclanding2_rr', 'pclanding3_rr'],
    healthLandingPage: ['healthlanding1_rr', 'healthlanding2_rr'],
    electronicsLandingPage: ['electronicslanding1_rr', 'electronicslanding2_rr'],
    babyandchildLandingPage: ['bclanding1_rr', 'bclanding2_rr', 'bclanding3_rr'],
  } as const;
  const pdpLookup = {
    // eventually append schemes product6_rr for PDP and product8_rr for PDP prestige
    PDP_FRONT_SHOP: CERTONA_SCHEMES.PDP_FRONT_SHOP,
    PDP_PRESTIGE: CERTONA_SCHEMES.PDP_PRESTIGE,
    A2C_OVERLAY: CERTONA_SCHEMES.A2C_OVERLAY,
  } as const;
  const favouritesLookup = {
    noFavourites: ['nofavourites3_rr'],
    favourites: ['favourites3_rr'],
  } as const;
  const pageLookup = {
    HOME: landingLookup[landing],
    CATEGORY: [CERTONA_SCHEMES.CATEGORY],
    SEARCH: [CERTONA_SCHEMES.SEARCH],
    FAVOURITES: favouritesLookup[hasFavourites],
    PDP_FRONT_SHOP: pdpLookup.PDP_FRONT_SHOP,
    PDP_PRESTIGE: pdpLookup.PDP_PRESTIGE,
    A2C_OVERLAY: pdpLookup.A2C_OVERLAY,
    QUICK_SHOP_PLP: [CERTONA_SCHEMES.QUICK_SHOP_PLP],
    MAY_LIKE_QUICK_SHOP_PLP: [CERTONA_SCHEMES.MAY_LIKE_QUICK_SHOP_PLP],
  } as const;
  return pageLookup[page];
};

export function setHrefLanguage(language: Locale): string {
  const hrefSubstring = window.location.search.substring(1);
  if (hrefSubstring.length === 0) {
    return window.location.href + `?lang=${language}`;
  } else if (hrefSubstring.startsWith('lang')) {
    return window.location.href
      .replace(/[?]*lang=fr/g, `?lang=${language}`)
      .replace(/[?]*lang=en/g, `?lang=${language}`);
  } else {
    return window.location.href.replace(/[&]*lang=fr/g, '').replace(/[&]*lang=en/g, '') + '&lang=' + language;
  }
}

export const formatBrandName = (name: string): string => {
  // Replace aski for apostrophe with actual apostrohpe
  if (name && name.includes('&#39;')) {
    return name.replace(/&#39;/g, "'");
  }
  return name;
};

export const isInViewport = (element: Element | null | undefined): boolean => {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  return rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
};

export const formatReviewCount = (reviewCount = 0): number | string => {
  return reviewCount >= 1000 ? `${parseFloat(String(reviewCount / 1000)).toFixed(2)}k` : reviewCount;
};

export const formatPercentageRating = (rating = 0): string => {
  return parseFloat(String(Math.max(0, Math.min(5, rating / 20)) * 20)).toFixed(2);
};

export const formatUnit = (unit: string): string => {
  const { MILLIMETERS, GRAMS } = UNIT_OF_MEASURMENT;
  const unitUpperCase = unit.toUpperCase();
  if (unitUpperCase === 'ML') return MILLIMETERS;
  if (unitUpperCase === 'G') return GRAMS;
  return capitalizeFirstLetter(unit);
};

export const formatPrice = (
  price: number,
  locale: Locale = LOCALE.EN,
  setFractionalDigits = true
): string | undefined => {
  if (price == null || price == undefined) return; // Is this necessary??
  return new Intl.NumberFormat(locale === LOCALE.FR ? INTL_LOCALE.FR : INTL_LOCALE.EN, {
    style: 'currency',
    currency: 'CAD',
    maximumFractionDigits: setFractionalDigits ? 2 : 0,
  }).format(price);
};

export const isBrandPLP = (categoryType: string): boolean => {
  return categoryType === 'brand' || categoryType === 'brandCategory';
};

export const sortRatings = (ratingFilter: FilterGroup) => {
  const orderedFilter: FilterGroup['filters'] = [];
  ratingFilter.filters.forEach((filter) => {
    orderedFilter[RATINGS_MAP[filter.code].location] = filter;
  });

  return orderedFilter;
};

export function removePlusSign(string: string): string {
  return string.replace(/[+]/g, ' ');
}

export function addPlusSign(string: string): string {
  return string.replace(/ /g, '+');
}

export const formatFilterCode = (productCode: string): string => encodeURIComponent(productCode).replace(/%20/g, '+');

export const extractFilter = (filterGroups: any, filterGroupCode: any) => {
  return Array.isArray(filterGroups) ? filterGroups.filter((filter) => filter.code === filterGroupCode) : [];
};

export const pageScrollPosition = (x: number, y: number): void => {
  window.scrollTo(x, y);
};

export const formatNumber = (number: number, locale: Locale): string => {
  if (locale === LOCALE.FR) {
    return new Intl.NumberFormat(INTL_LOCALE.FR).format(number);
  }
  return new Intl.NumberFormat().format(number);
};

export const parseHTML = function (html: string): string | undefined {
  const domParser = new DOMParser();
  return domParser.parseFromString(html, 'text/html')?.body?.innerText;
};

export const parseJSON = function (jsonStr: string): unknown {
  if (typeof jsonStr !== 'string') return null;
  try {
    return JSON.parse(jsonStr);
  } catch (err) {
    return null;
  }
};

export const roundDownMakeString = (number: number): string => {
  return Math.floor(number).toString();
};

export const getRibbonsAndOffers = (badges: Badge[] = []) => {
  const ribbons = badges.filter((badge) => badge.type === 'RIBBON');
  const offers = badges.filter((badge) => badge.type === 'OFFER');
  return [ribbons, offers];
};

export const getPowerReviewsConfig = (locale: Locale = LOCALE.EN): PowerReviewsConfig => {
  if (locale === LOCALE.EN) {
    return {
      merchant_group_id: POWER_REVIEWS.EN.MERCHANT_GROUP_ID,
      merchant_id: POWER_REVIEWS.EN.MERCHANT_ID,
      api_key: POWER_REVIEWS.EN.API_KEY,
    };
  }
  return {
    merchant_group_id: POWER_REVIEWS.FR.MERCHANT_GROUP_ID,
    merchant_id: POWER_REVIEWS.FR.MERCHANT_ID,
    api_key: POWER_REVIEWS.FR.API_KEY,
  };
};

export const checkUserAnonymous = (status: string): boolean => {
  return status.toUpperCase() === LoggedInStatus.ANONYMOUS || status.toUpperCase() === LoggedInStatus.GUEST_LOGGED_IN;
};

// new Date returns a Date object based on local time
export const getMinutesFromDate = (date: string | number | Date): string => {
  const dateObject = new Date(date);
  const minutes = dateObject.getMinutes();
  if (minutes === 0) {
    return '00';
  }
  if (minutes < 10) {
    return `0${minutes}`;
  }
  return `${minutes}`;
};

export const getHourFromDate = (date: string | number | Date): number => {
  const hour = new Date(date).getHours();
  return hour;
};

export const getFormattedTime = (date: string | number | Date, locale: Locale): string => {
  const hour = getHourFromDate(date);
  const minutes = getMinutesFromDate(date);

  if (locale === LOCALE.FR) {
    return `${hour}h ${minutes}`;
  } else {
    if (hour === 0) {
      return `12:${minutes}am`;
    } else if (hour < 12 && hour !== 0) {
      return `${hour}:${minutes}am`;
    } else if (hour > 12) {
      return `${hour - 12}:${minutes}pm`;
    }
  }

  return 'invalid date';
};

export const getMonth = (date: string | number | Date, locale: Locale): string => {
  const dateObject = new Date(date);
  return (locale === LOCALE.FR ? MONTH_ABBREVIATONS.FR : MONTH_ABBREVIATONS.EN)[dateObject.getMonth()];
};

export const getFormattedMonthAndDay = (date: string | number | Date, locale: Locale): string => {
  const dateObject = new Date(date);
  const month = getMonth(date, locale);
  const day = dateObject.getDate();

  if (locale === LOCALE.FR) {
    return `${day} ${month}`;
  } else return `${month} ${day}`;
};

export const getFormattedMonthDayAndYear = (date: string | number | Date, locale: Locale): string => {
  if (!date) return '';
  const dateObject = new Date(date);
  const month = getMonth(date, locale);
  const day = dateObject.getDate();
  const year = dateObject.getFullYear();
  const formattedDate = locale === LOCALE.FR ? `${day} ${month}` : `${month} ${day}`;

  return `${formattedDate}, ${year}`;
};

export const getTimeZone = (locale: Locale): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const region = locale === LOCALE.FR ? 'fr-CA' : 'en-CA';
  const zone = new Date().toLocaleTimeString(region, { timeZone: timeZone, timeZoneName: 'short' }).split(' ');
  return zone[zone.length - 1];
};

export const getFormattedExpiryDate = (
  endDate: string | number | Date,
  locale: Locale,
  formattedMessage: string
): string => {
  const monthAndDay = getFormattedMonthAndDay(endDate, locale);
  const time = getFormattedTime(endDate, locale);
  const userTimeZoneAbbreviation = getTimeZone(locale);
  return ` ${monthAndDay} ${formattedMessage} ${time} ${userTimeZoneAbbreviation}`;
};

export const getScrollTop = (): number => {
  return document.documentElement.scrollTop;
};

export const isScrollPositionBeyondThreshold = (threshold = 0): boolean => {
  const scrollTop = getScrollTop();
  return scrollTop > threshold;
};

export const scrollComponentRefIntoView = (componentRef: React.RefObject<Element>) =>
  componentRef.current?.scrollIntoView({ behavior: 'smooth' });

export const scrollComponentRefIntoViewHorizontally = (componentRef: React.RefObject<Element>) =>
  componentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

export const scrollToTopOfPage = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const checkBoxKeyDownHandler = ({ event, id }: { event: KeyboardEvent; id: string }) => {
  const { SPACE, ENTER } = ACCESSIBILITY_KEYCODES;
  if (event.keyCode === SPACE || event.keyCode === ENTER) {
    event.preventDefault();
    const checkBox = document.getElementsByName(id);
    if (checkBox[0]) {
      checkBox[0].click();
    }
  }
};

export const checkIfBagIsEmpty = (bagCount: string): boolean => parseInt(bagCount) === 0;

export const roundToTwoDecimalsIfAvailable = (number: number): number => {
  return Math.round(number * 100) / 100;
};

/**
 * Generates utils to check whether the passed in value empty
 * @param {data} any
 * @returns {boolean} true or false
 */
export const isEmpty = (val: unknown): boolean => {
  if (typeof val === 'string' || Array.isArray(val)) {
    return val.length === 0;
  } else if (typeof val === 'undefined' || Number.isNaN(val) || val === null) {
    // 0 is not an empty value
    return true;
  } else if (typeof val === 'object') {
    return isEmpty(Object.keys(val));
  }
  return false;
};

export const generateCreativeId = (image: string): string | null => {
  if (image === '') return null;
  const creative_id = image.split('/').pop()?.split('.')[0];
  return creative_id ? sanitizeCreativeId(creative_id) : null;
};

const sanitizeCreativeId = (creativeId: string): string => {
  const sanitizedQuery = creativeId.replace(/[`\s!@#$%^&*()+\\=[\]{};':"\\|,.<>\\/?~]/g, '').toLowerCase();
  return sanitizedQuery;
};
export interface IProduct {
  product: { code: string };
  quantity: number;
}

export const getCartProducts = (products: IProduct[]) => {
  let entries: any = {};
  if (products?.length > 0) {
    for (const itm of products) {
      entries[itm?.product?.code] = {
        quantity: itm?.quantity,
      };
    }
  }
  return entries;
};

export const getPageId = (viewDefinition: any): string => {
  const { SLUG, BRAND, CATEGORY, SEARCH_TERM, SEARCH_REDIRECT } = VIEW_RESOLVER;

  return (
    viewDefinition[SLUG] ||
    viewDefinition[BRAND] ||
    viewDefinition[SEARCH_TERM] ||
    viewDefinition[CATEGORY] ||
    viewDefinition[SEARCH_REDIRECT][SEARCH_TERM][0]
  );
};

export const getSelectedProvinceCookie = (): string | undefined => {
  const selectedProvince = Cookies.get(cookies.USER_SELECTED_PROVINCE);
  return selectedProvince;
};

export const setSelectedProvinceCookie = (selectedProvince: string): void => {
  const cookieParameter: Cookies.CookieAttributes = { expires: 365 };

  const requiredDomain = isPharmaprixBanner() ? 'pharmaprix.ca' : 'shoppersdrugmart.ca';

  //TODO: use new util/auth fcn that will be merged
  if (process.env.NEXT_PUBLIC_VIEW_RESOLVER_ENV === environment.PRODUCTION) {
    cookieParameter.domain = requiredDomain;
    // clean up old cookie assigned to the subdomain so it doesn't get in the way
    Cookies.remove(cookies.USER_SELECTED_PROVINCE);
  }
  Cookies.set(cookies.USER_SELECTED_PROVINCE, selectedProvince, cookieParameter);
};

// Salesfloor accessibility "fix"
export const initializeSalesfloorAccessibility = () => {
  const checkSalesfloorInterval = setInterval(() => {
    const salesFloorIframe = document.getElementById('sf-widget-cookie');

    if (salesFloorIframe) {
      if (salesFloorIframe.style.overflow === 'hidden') {
        salesFloorIframe.setAttribute('role', 'presentation');
      } else {
        salesFloorIframe.setAttribute('title', 'Connect with a Beauty Specialist');
      }

      clearInterval(checkSalesfloorInterval);
    }
  }, 1000);

  return () => {
    clearInterval(checkSalesfloorInterval);
  };
};
