import React, { useEffect, useState, FC } from 'react';
import { useSDMCartContext } from '@ldp/sdm-contexts';

import useGetUserPcoData from '@/hooks/use-get-user-pco-data';
import { useStore } from '@/store/useStore';
import { useUpdateCartItemQuantity } from '@/api/use-update-cart-item';
import { useAddToCart } from '@/api/use-add-to-cart';
import { LoggedInStatus } from '@/data/constants';
import { createCartGuidCookie, getLoggedInStatus, getCartProducts } from '@/utils';
import { ICartController } from './cart-controller.interface';

const CartController: FC<ICartController> = ({ children }) => {
  const [cartFlag, setCartFlag] = useState(true);
  const { cartCount, selectedProduct, isUpdateAction, populateEnteries, setIsInitialCartCountUpdated } =
    useSDMCartContext();
  const { productCode, quantity } = selectedProduct ? selectedProduct : { productCode: null, quantity: 0 };
  const { data: addToCartData, addToCart } = useAddToCart();
  const { createUpdateCartItemQuantityMutation } = useUpdateCartItemQuantity();
  const cartData = useStore(({ cartData }) => cartData);

  const invokeAddToCartMutation = () => {
    addToCart({ productId: productCode, quantity: quantity });
  };

  useEffect(() => {
    if (!isUpdateAction && Object.entries(selectedProduct ?? {}).length > 0) {
      invokeAddToCartMutation();
    } else if (isUpdateAction) {
      createUpdateCartItemQuantityMutation(productCode, quantity);
    }
  }, [cartCount, isUpdateAction]);

  useEffect(() => {
    const data = addToCartData && addToCartData?.cart;
    const guid = data && data.id;
    if (guid && getLoggedInStatus() === LoggedInStatus.ANONYMOUS) {
      createCartGuidCookie(guid);
    }
  }, [addToCartData]);

  const cartEntries = cartData?.data?.entries || [];
  const cartGiftCardEntries = cartData?.data?.giftCardEntries || [];
  const cartProducts = getCartProducts([...cartEntries, ...cartGiftCardEntries]);

  useEffect(() => {
    if (cartProducts && cartFlag && Object.entries(selectedProduct ?? {}).length === 0) {
      populateEnteries(cartProducts);
      setCartFlag(false);
    }
  }, [cartProducts]);

  useEffect(() => {
    if (!cartFlag) {
      setIsInitialCartCountUpdated(true);
    }
  }, [cartFlag]);

  //getUserPcoData for analytics
  useGetUserPcoData();

  return <>{children}</>;
};

export default CartController;
