import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SDMContexts } from '@ldp/sdm-contexts';
import { IntlProvider } from 'react-intl';
import { useDetermineLocale, useSDMContextProps } from '@/hooks';
import styles from './container.module.css';

import GlobalState from '@/components/global-state';
import CartController from '@/components/cart-controller';
import messages, { type Locale } from 'I18n/translations';
import { LayoutProps } from './layout.interface';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function Layout({ children, cartData, viewDefinitionResults, kamFlags }: LayoutProps) {
  const locale = useDetermineLocale();
  const [preferredLocale, setPreferredLocale] = useState<Locale>('en');
  const sdmContextProps = useSDMContextProps();

  useEffect(() => setPreferredLocale(locale), [locale]);

  return (
    <GlobalState
      cartData={cartData ?? undefined}
      viewDefinitionResults={viewDefinitionResults ?? undefined}
      kamFlags={kamFlags ?? null}
    >
      <SDMContexts {...sdmContextProps}>
        <IntlProvider
          locale={preferredLocale}
          messages={messages[preferredLocale]}
          onError={() => {
            return 'no error';
          }}
        >
          <QueryClientProvider client={queryClient}>
            <CartController>
              <div className={styles.container} id="main-content">
                {children}
              </div>
            </CartController>
          </QueryClientProvider>
        </IntlProvider>
      </SDMContexts>
    </GlobalState>
  );
}
