// @ts-nocheck
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import jwt_decode from 'jwt-decode';

import {
  banners,
  cookies,
  COOKIE_DELTA,
  DATASET_ATTRIBUTES_VALUES,
  environment,
  LoggedInStatus,
} from '@/data/constants';
import { isPharmaprixBanner } from 'Utils/utils';
import { Request } from '@/components/authored-page-routes/authored-page-routes.interface';

/**
 * @param req - if a request object is provided, assume SSR and use request headers to grab hostname, otherwise use
 * window
 */
export const getSiteId = (req?: Request): string => (isPharmaprixBanner(req) ? banners.PHARMAPRIX : banners.SHOPPERS);

/**
 * The customer cart guid as a LOGGEDIN user. If the guid does not exist yet, fall back to the guest cart guid
 */
export const getCustomerExistingCartGuid = (key = 'customer-cart'): string => {
  const siteId = getSiteId();
  const guid = Cookies.get(`${siteId}-${key}`);
  // modifiedDate lets us know the last time an item was added to the bag as a LOGGEDIN user.
  const modifiedDate = Cookies.get(`${siteId}-${key}-modified-date`);
  const cookieExpired = checkIfCustomerCookieExpired(modifiedDate);
  if (cookieExpired) return '';

  // Fall back to guest cart guid if guid does not exist
  return guid ? guid : getExistingCartGuid();
};

/**
 * Function to check if the cookie is expired where the modified date is not updated for more than 14 days.
 */
export const checkIfCustomerCookieExpired = (modifiedDate: string | undefined): boolean => {
  return (
    getLoggedInStatus() === LoggedInStatus.CURRENT &&
    new Date().getTime() > Number(modifiedDate) + Number(COOKIE_DELTA.TWO_DAYS)
  );
};

export const getIsModernizedBag = (): boolean => {
  if (typeof window !== 'undefined') {
    //@ts-ignore
    return String(window?.modernizeBag).toLowerCase() === 'true';
  }
  return false;
};

// The cart guid as an ANNONYMOUS user.
export const getExistingCartGuid = (): string => {
  const siteId = getSiteId();
  const heliosGuid = Cookies.get(`${siteId}-cart-helios`);
  // modifiedDate lets us know the last time an item was added to the bag as an ANNOYMOUS user.
  const modifiedDate = Cookies.get(`${siteId}-cart-helios-modified-date`);
  const cookieExpired = checkIfCookieExpired(modifiedDate);
  if (cookieExpired) return '';

  return heliosGuid || '';
};

/**
 * Function to check if the cookie is expired where the modified date is not updated for more than 14 days.
 */
export const checkIfCookieExpired = (modifiedDate: string | undefined): boolean => {
  return (
    getLoggedInStatus() === LoggedInStatus.ANONYMOUS &&
    //Commented out for BFCM
    //TODO: Post BFCM reset anonymous user cart cookie expiry back to 14 days, temp change to 2 days for BFCMM
    new Date().getTime() > Number(modifiedDate) + Number(COOKIE_DELTA.TWO_DAYS)
  );
};

/** @deprecated this token was for Hybris, which we are no longer using */
export const getAccessToken = (): string => {
  const accessToken = Cookies.get(cookies.ACCESS_TOKEN);
  return accessToken || '';
};

/**
 * @param req - if a request object is provided, assume SSR and use request cookies to grab token, otherwise try
 * document cookies
 */
export const getCiamAccessToken = (req?: Request): string | undefined => {
  const { UAT, PRODUCTION } = environment;

  const isUAT = req
    ? req.headers?.host.includes(UAT)
    : typeof window !== 'undefined' && window?.location?.hostname.includes(UAT);
  if (process.env.NODE_ENV === PRODUCTION && !isUAT) {
    const globalToken = req?.cookies?.ciamAccessTokenGlobal || Cookies.get(cookies.CIAM_ACCESS_TOKEN_GLOBAL);
    if (globalToken) {
      return globalToken;
    }
  }

  return req?.cookies?.ciamAccessToken || Cookies.get(cookies.CIAM_ACCESS_TOKEN);
};

/**
 * @param req - if a request object is provided, assume SSR and use request cookies to grab token, otherwise try
 * document cookies
 * @returns contrary to its name, this function returns either an empty string or a string in the form `"Bearer ${TOKEN}"`
 */
export const isLoggedIn = (req?: Request): `Bearer ${string}` | '' => {
  //get the token
  const ciamAccessToken = getCiamAccessToken(req);

  //check if the token is available
  if (ciamAccessToken) {
    //check if token is not expired
    return hasTokenExpired(ciamAccessToken) ? '' : `Bearer ${ciamAccessToken}`;
  }
  return '';
};

export const isAnonymous = (): boolean => {
  return !isLoggedIn();
};

export const getLoggedInStatus = (): string => {
  return isLoggedIn() ? LoggedInStatus.CURRENT : LoggedInStatus.ANONYMOUS;
};

export const createCartGuidCookie = (guid: string): void => {
  const siteId = getSiteId();
  const requiredDomain = isPharmaprixBanner() ? 'pharmaprix.ca' : 'shoppersdrugmart.ca';
  const cookieParameter: Cookies.CookieAttributes = { expires: 365 * 10 };
  if (process.env.NODE_ENV === environment.PRODUCTION) {
    cookieParameter.domain = requiredDomain;
  }
  Cookies.set(`${siteId}-cart-helios`, guid, cookieParameter);
  Cookies.set(`${siteId}-cart-helios-modified-date`, new Date().getTime().toString(), cookieParameter);
};

export const clearCartGuidCookie = (key = 'cart-helios'): void => {
  if (!getIsModernizedBag()) {
    key = 'cart';
  }
  const siteId = getSiteId();
  Cookies.remove(`${siteId}-${key}`);
};

export const getResTrackingIDCookie = (): string => {
  let trackingId = Cookies.get(cookies.RES_TRACKINGID);
  if (!trackingId) {
    trackingId = uuidv4();
    Cookies.set(cookies.RES_TRACKINGID, trackingId, { expires: 365 * 10 });
  }
  return trackingId;
};

export const getLoggedInUserInfo = (): Ciam | null => {
  if (!isLoggedIn()) {
    return null;
  }
  const accessToken: string | undefined = getCiamAccessToken();
  if (!accessToken) {
    return null;
  }
  return jwt_decode<Ciam>(accessToken);
};

export const getLoggedInUserInfoSSR = (token: any): Ciam | null => {
  if (!token || hasTokenExpired(token)) {
    return null;
  }
  const userInfo = jwt_decode(token);
  return userInfo;
};

export const getLoggedInUserEmail = (): string | null | undefined => {
  const userInfo = getLoggedInUserInfo();
  if (!userInfo) {
    return null;
  }
  const { sub } = userInfo;
  return sub;
};

export const hasTokenExpired = (token: string): boolean => {
  const { exp } = jwt_decode<Ciam>(token);
  return Date.now() >= exp * 1000;
};

export const shouldRedirectUser = (userStatus: string): boolean => {
  const token = isLoggedIn();
  const redirectUser =
    (userStatus === DATASET_ATTRIBUTES_VALUES.SOFT_LOGIN || userStatus === DATASET_ATTRIBUTES_VALUES.HARD_LOGIN) &&
    (!token || hasTokenExpired(token));
  return redirectUser;
};

export const shouldRedirectToSignIn = (): boolean => {
  const token = isLoggedIn();
  return !token || hasTokenExpired(token);
};

export const isUserLoggedIn = (): boolean => {
  if (getLoggedInStatus() === LoggedInStatus.ANONYMOUS) {
    return false;
  } else if (getLoggedInStatus() === LoggedInStatus.CURRENT) {
    const token: string | undefined = getCiamAccessToken();
    if (token) {
      return !hasTokenExpired(token);
    }
  }
  return false;
};

export const getUserCookieStatus = (): string | null => {
  const userData = Cookies.get(cookies.USER_STATUS);
  return userData ? JSON.parse(decodeURI(userData)) : null;
};

export const setUserCookieStatus = (body: any): string | undefined => {
  return Cookies.set(cookies.USER_STATUS, encodeURI(JSON.stringify(body)));
};

export const clearCookie = (key: string): void => Cookies.remove(key);

export const getAkamaiOriginSessionCookie = () => Cookies.get(cookies.AKAMAI_ORIGIN_SESSION_COOKIE);

export const getGuid = () => {
  return getLoggedInStatus() === LoggedInStatus.CURRENT ? '' : getExistingCartGuid();
};

// todo: change this to proper return type when we migrate over to helios
export const getForceHeliosCookie = (): any => {
  const forceHelios = Cookies.get(cookies.FORCE_HELIOS);
  return forceHelios ? { forceHelios } : {};
};

// we can expand on this when we need to
type Ciam = {
  exp: number;
  pcid: string;
  sub?: string;
};
