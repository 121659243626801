import { LoggedInStatus, PAGE_ID } from '@/data/constants';
import { IPCOptimumAccount } from '@ldp/sdm-contexts';
import { ContextKey, EventName, getDataLayerName, initializeSnowplow, snowplow } from '@sdm/analytics-helper-library';
import Cookies from 'js-cookie';
import { getExistingCartGuid, getLoggedInStatus, getLoggedInUserInfo } from 'Utils/auth';
import { IGlobalContextFields } from './analytics.interface';
import { HELIOS_ANALYTICS_EVENT_TYPES, MEDIA_PIXELS, SNOWPLOW, USER_CONTEXT } from './constants';
import {
  IAdContext,
  ICartContext,
  IComponentContext,
  IPageContext,
  IPaginationContext,
  IProductContext,
  IUserContext,
} from './snowplow-context.interface';

export const dataLayerName: string = getDataLayerName();

const { PAGE_NAMES, PAGE_TEMPLATES, PAGE_SECTIONS, EVENT_NAMES } = SNOWPLOW;

const { HOME_ELECTRONICS, LANDING, BABY_CHILD, PERSONAL_CARE, HEALTH, BEAUTY } = PAGE_ID;
const {
  HOME_LANDING_PAGE,
  PERSONAL_CARE_LANDING_PAGE,
  HEALTH_LANDING_PAGE,
  ELECTRONICS_LANDING_PAGE,
  BABY_AND_CHILD_LANDING_PAGE,
  BEAUTY_LANDING_PAGE,
} = PAGE_NAMES;

const {
  HELIOS_ADD_TO_CART,
  HELIOS_PRODUCT_CAROUSEL,
  HELIOS_PRODUCT_EVENT,
  HELIOS_AD_TRACKING,
  HELIOS_NAV_CLICK,
  HELIOS_PAGE_TRACKING,
  HELIOS_SORT_TRACKING,
  HELIOS_FILTER_TRACKING,
  HELIOS_REFRESH_LINKS_CLICK_TRACKING,
  PRODUCT_CLICK,
} = HELIOS_ANALYTICS_EVENT_TYPES;

/**
 * A function to to toggle the snowplow collector endponts via the query parameter
 * Usage: For instance, adding snowplow-env="qa" as a parameter
 * would direct data into the snowplow qa env
 * @returns {string} The desire Snowplow collector endpoint
 */
export const getSnowplowEnv = () => {
  const snowplowEnvParam = new URLSearchParams(window.location.search).get('snowplow-env');
  switch (snowplowEnvParam) {
    case 'qa':
      return SNOWPLOW.ENV.QA;
    case 'dev':
      return SNOWPLOW.ENV.DEV;
    case 'prod':
      return SNOWPLOW.ENV.PROD;
    default:
      return process.env.NEXT_PUBLIC_SNOWPLOW_ENV === 'prod' ? SNOWPLOW.ENV.PROD : SNOWPLOW.ENV.DEV;
  }
};

export const initializeAnalytics = () => {
  const isVerbose = process.env.NEXT_PUBLIC_SNOWPLOW_IS_VERBOSE === 'true';
  const snowPlowEnv = getSnowplowEnv();

  const configOptions = {
    enableLinkClickAutoTracking: true,
    isVerbose: isVerbose,
  };

  initializeSnowplow(snowPlowEnv, configOptions);
};

export const mapPageIdToPageName: { [key: string]: string } = {
  [HOME_ELECTRONICS]: ELECTRONICS_LANDING_PAGE,
  [LANDING]: HOME_LANDING_PAGE,
  [BABY_CHILD]: BABY_AND_CHILD_LANDING_PAGE,
  [PERSONAL_CARE]: PERSONAL_CARE_LANDING_PAGE,
  [HEALTH]: HEALTH_LANDING_PAGE,
  [BEAUTY]: BEAUTY_LANDING_PAGE,
};

export const mapPageIdToPageTemplate = (pageId: string) => {
  const departmentLandingPages: string[] = [HOME_ELECTRONICS, BABY_CHILD, PERSONAL_CARE, HEALTH];
  if (departmentLandingPages.includes(pageId)) {
    return PAGE_TEMPLATES.DEPARTMENT_TEMPLATE;
  }
  return null;
};

/**
 * A function to normalize a value string to be used for Snowplow.
 * This converts a string to only use lowercase printable ASCII characters with dashes.
 * See https://aticleworld.com/printable-ascii-characters-list/ for accepted characters.
 *
 * @param {string} text - the text to convert to a value for Snowplow
 * @returns {string}
 */
export const normalizeStringValueForSnowplow = (text: string): string => {
  if (text === '') return '';
  const normalizedText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Normalize French characters
  const cleanText = normalizedText.replace(new RegExp(`[^ -~]|[$]|[_+]`, 'gu'), ' ').trim(); // Remove all non-ASCII characters and dollar signs
  const spText = cleanText.toLowerCase().replace(/\s+/g, '-'); //  Convert text to lowercase, replace all spaces with dashes
  return spText;
};

/**
 * A Snowplow helper function to check whether the event has already been created. This is done by checking for existing events in the window.analyticsLayer with the same name, and whether the latest instance of the event has the same specified data
 *
 * @param {string} eventName - the Snowplow event name
 * @param {string} dataObjectPath - the key or path (in dot notation) within the window.analyticsLayer to the existing object data
 * @param {Object|string} newData - the new data to validate if it is the same as within the existing analyticsLayer object
 * @param {integer} threshold - how many events to look back at
 * @returns {boolean}
 */

export const hasExistingEvent = (eventName: string, dataObjectPath: string, newData: Object | string): boolean => {
  const existingEvents = window?.[dataLayerName]?.filter((analyticsEvent) => analyticsEvent.event === eventName);
  // Get the data in the existing event by traversing the path specified by the dataObjectPath
  return (
    existingEvents?.some((event) => {
      const existingObjectValue = dataObjectPath.split('.').reduce((accumulator, currentValue) => {
        return accumulator?.[currentValue];
      }, event);
      return Boolean(event && JSON.stringify(existingObjectValue) === JSON.stringify(newData));
    }) ?? false
  );
};

const getUserContextData = (pcOptimumAccount: IPCOptimumAccount): IUserContext => {
  const { walletId } = pcOptimumAccount || {};
  const { ANONYMOUS, CURRENT, GUEST_LOGGED_IN } = LoggedInStatus;
  const { REGISTRATION_STATUS } = USER_CONTEXT;

  const mapLoginStatus: { [key: string]: string } = {
    [ANONYMOUS]: 'not-logged-in',
    [CURRENT]: 'logged_in',
    [GUEST_LOGGED_IN]: 'guest_logged_in',
  };

  const getUserStatus = (status: string) => {
    if (mapLoginStatus[status]) {
      return mapLoginStatus[status];
    } else {
      return 'not-logged-in';
    }
  };

  const getUserPCId = () => {
    const ciamToken = getLoggedInUserInfo();
    const pcid = ciamToken?.pcid ?? '';
    return pcid;
  };

  const userContextData: IUserContext = {
    pcid_id: getUserPCId(),
    login_status: getUserStatus(getLoggedInStatus()),
    registration_status: walletId ? REGISTRATION_STATUS.REGISTERED : REGISTRATION_STATUS.UNKNOWN,
    pco_wallet_id: walletId ?? null,
  };
  return userContextData;
};

const getCartContextData = (cartCount: number): ICartContext => {
  const guid = getExistingCartGuid();

  const cartContextData = {
    cart_id: guid,
    cart_status: cartCount === 0 ? 'empty' : 'open',
    assortment_type: '',
    parent_cart_id: guid,
  };

  return cartContextData;
};

const getPageContextData = (pageId: string, locale: string, currentDevice: string): IPageContext => {
  const pageName = mapPageIdToPageName[pageId] || pageId;
  const pageContextData = {
    page_name: pageName,
    page_section: PAGE_SECTIONS.LANDING,
    page_language: locale,
    page_template: mapPageIdToPageTemplate(pageId),
    site_type: currentDevice,
    page_url: window.location.href,
  };
  return pageContextData;
};

export const trackSnowplow = (analyticsData: any, globalContextFields: IGlobalContextFields) => {
  const { cartCount, locale, currentDevice, pageId, pcOptimumAccount } = globalContextFields ?? {};

  if (window[dataLayerName])
    window[dataLayerName]?.push({
      ...analyticsData,
      [ContextKey.Cart]: getCartContextData(cartCount),
      [ContextKey.Page]: getPageContextData(pageId, locale, currentDevice),
      [ContextKey.User]: getUserContextData(pcOptimumAccount!),
    });
  return;
};

export const getProductContextData = (context: any): IProductContext => {
  const { brand, code, name, quantity, badges, sponsored, sellerName, prices } = context.productContext;
  const productContextData = {
    product_article_number: '',
    product_upc: code,
    product_name: name,
    product_quantity: quantity,
    product_loyalty_badge: badges?.loyaltyBadge || null,
    product_deal_badge: badges?.dealBadge?.text || '',
    product_text_badge: badges?.textBadge || null,
    product_price: prices.price.value,
    product_was_price: prices.wasPrice?.value,
    product_brand: brand,
    product_sponsor: null,
    product_is_sponsored: sponsored || null,
    product_catalog: null,
    product_seller: sellerName,
    product_position: context.productPosition ?? null,
    product_liam: `SDM_${code}`,
  };
  return productContextData;
};

export const getAdProductContextData = (context: any): IProductContext => {
  const product = context.product;
  const adProductContextData = {
    product_article_number: product?.articleNumber || null,
    product_upc: product?.code || null,
    product_name: product?.name || null,
    product_quantity: product?.quantity || null,
    product_loyalty_badge: product?.badges?.loyaltyBadge || null,
    product_deal_badge: product?.badges?.dealBadge?.text || null,
    product_text_badge: product?.badges?.textBadge || null,
    product_price: product?.prices ? product.prices.price?.value : product?.pricing.price || null,
    product_was_price: product?.prices ? product.prices.wasPrice?.value : null,
    product_brand: product?.brand || null,
    product_sponsor: null,
    product_is_sponsored: product?.isSponsored || null,
    product_catalog: null,
    product_seller: product?.sellerName || null,
    product_position: product?.productPosition ?? null,
    product_liam: product?.code ? `SDM_${product?.code}` : null,
  };

  return adProductContextData;
};
export const getProductsArrayContextData = (context: any): IProductContext[] => {
  const productsArray = context.products.map((product: any) => {
    const { code, name, badges, prices, brand, sponsored, productPosition } = product;
    return {
      product_article_number: '',
      product_upc: code,
      product_name: name,
      product_loyalty_badge: badges?.loyaltyBadge || null,
      product_deal_badge: badges?.dealBadge?.text || '',
      product_text_badge: badges?.textBadge || null,
      product_price: prices.price.value,
      product_was_price: prices.wasPrice?.value,
      product_brand: brand,
      product_is_sponsored: sponsored || null,
      product_position: productPosition ?? null,
      product_liam: `SDM_${code}`,
    };
  });
  return productsArray;
};

export const getAdContextData = (context: any): IAdContext => {
  const {
    serving_id,
    placement_id,
    ad_group_id,
    brand,
    campaign_id,
    creative_id,
    encrypted_cost,
    keyword,
    product_article_number,
    target_keyword,
    target_match_type,
    target_page_type,
    creative_cost,
  } = context.ad || {};

  const adContextData: IAdContext = {
    serving_id: serving_id ? serving_id : ' ',
    placement_id: placement_id || null,
    campaign_id: campaign_id || '',
    ad_group_id: ad_group_id || null,
    //cost not supplied by helios
    cost: null,
    encrypted_cost: encrypted_cost || creative_cost?.encrypted_cost || null,
    keyword: keyword || null,
    creative_id: creative_id || null,
    product_article_number: product_article_number || null,
    brand: brand || null,
    target_keyword: target_keyword || null,
    target_match_type: target_match_type || null,
    target_page_type: target_page_type || null,
  };
  return adContextData;
};
const getComponentContextData = (context: any, pageId: string): IComponentContext => {
  const { engine, id, name, number_of_children, type, click_action, click_element, hierarchy, placement } =
    context.component || {};
  const componentContextData = {
    id,
    name,
    type,
    //use page_name value if placement is not provided
    placement: placement || mapPageIdToPageTemplate(pageId),
    //position not supplied by helios yet
    position: null,
    number_of_children,
    engine,
    click_action: click_action || null,
    click_element: click_element || null,
    hierarchy: hierarchy || null,
  };
  return componentContextData;
};

export const getSnowplowUserData = () => {
  const spCookie = '_spvid_id';
  const spvidCookie = Cookies.get();

  if (spvidCookie && typeof spvidCookie === 'object') {
    for (const [key, value] of Object.entries(spvidCookie)) {
      if (key.startsWith(spCookie)) {
        const split = value?.split('.');
        return {
          domainUserId: split[0],
          sessionId: split[5],
        };
      }
    }
  }

  return {};
};

const getPaginationContextData = (context: any): IPaginationContext => {
  const { currentPage, pageSize, totalResults } = context.pagination;
  const paginationContextData = {
    current_page: currentPage,
    page_size: pageSize,
    total_results: totalResults,
  };
  return paginationContextData;
};

const trackMediaPixelsAddToCart = (cartCount: number) => {
  const { ADD_TO_BAG, ADD_TO_BAG_OPEN, CUSTOM_LINK } = MEDIA_PIXELS;

  window?.dataLayer?.push({
    event: CUSTOM_LINK,
    linkName: cartCount > 0 ? ADD_TO_BAG : ADD_TO_BAG_OPEN,
  });
};

export const trackCartChanges = (context: any, globalContextFields: IGlobalContextFields) => {
  trackMediaPixelsAddToCart(globalContextFields?.cartCount);
  trackSnowplow(
    {
      event: EVENT_NAMES.PRODUCT_CLEAR,
      [ContextKey.Product]: null,
    },
    { ...globalContextFields }
  );
  trackSnowplow(
    {
      event: EVENT_NAMES.AD_CLEAR,
      [ContextKey.Ad]: null,
    },
    { ...globalContextFields }
  );

  //snowplow events include add_to_cart, increase_quantity, decrease_quantity, remove_from_cart
  trackSnowplow(
    {
      event: context.event,
      [ContextKey.Product]: getProductContextData(context),
      [ContextKey.Ad]: getAdContextData(context),
      [ContextKey.Component]: getComponentContextData(context, globalContextFields.pageId),
    },
    { ...globalContextFields }
  );
};

export const trackProductCarousel = (context: any, globalContextFields: IGlobalContextFields) => {
  const { event } = context;
  //only track carousel events for the same component id once
  if (!hasExistingEvent(context.event, 'component.id', context.component.id)) {
    trackSnowplow(
      {
        event: EVENT_NAMES.COMPONENT_CLEAR,
        [ContextKey.Product]: null,
        [ContextKey.Component]: null,
      },
      { ...globalContextFields }
    );

    //snowplow events include: carousel_load,  carousel_view
    trackSnowplow(
      {
        event,
        [ContextKey.Product]: getProductsArrayContextData(context),
        [ContextKey.Component]: getComponentContextData(context, globalContextFields.pageId),
      },
      { ...globalContextFields }
    );
  }
};

export const trackProduct = (context: any, globalContextFields: IGlobalContextFields) => {
  trackSnowplow(
    {
      event: EVENT_NAMES.COMPONENT_CLEAR,
      [ContextKey.Product]: null,
      [ContextKey.Component]: null,
    },
    { ...globalContextFields }
  );

  //snowplow events include: product_click,
  trackSnowplow(
    {
      event: context.event,
      [ContextKey.Product]: getProductsArrayContextData(context),
      [ContextKey.Component]: getComponentContextData(context, globalContextFields.pageId),
    },
    { ...globalContextFields }
  );
};

export const trackAdEvents = (context: any, globalContextFields: IGlobalContextFields) => {
  //only track Ad events for the same campaign_id once
  if (!hasExistingEvent(context.event, 'ad.campaign_id', context.ad.campaign_id)) {
    //ad_clear event is pushed to analyticsLayer to null any previous ad parameter
    trackSnowplow(
      {
        event: EVENT_NAMES.AD_CLEAR,
        [ContextKey.Ad]: null,
      },
      { ...globalContextFields }
    );
    //ad snowplow events include: ad_load, ad_view, ad_click
    trackSnowplow(
      {
        event: context.event,
        [ContextKey.Ad]: getAdContextData(context),
        [ContextKey.Product]: getAdProductContextData(context),
      },
      { ...globalContextFields }
    );
  }
};

export const trackNavClicks = (context: any, globalContextFields: IGlobalContextFields) => {
  //component_clear event is pushed to analyticsLayer to null any previous parameter
  trackSnowplow(
    {
      event: EVENT_NAMES.COMPONENT_CLEAR,
      [ContextKey.Component]: null,
      custom_engagement: null,
    },
    { ...globalContextFields }
  );

  trackSnowplow(
    {
      event: EVENT_NAMES.UI_ENGAGEMENT,
      custom_engagement: {
        action: 'click',
      },
      [ContextKey.Component]: getComponentContextData(context, globalContextFields.pageId),
    },

    { ...globalContextFields }
  );
};

export const trackPage = (context: any, globalContextFields: IGlobalContextFields) => {
  // product_pagination_clear event is pushed to analytics layer to null any previous parameter
  trackSnowplow(
    {
      event: EVENT_NAMES.PRODUCT_PAGINATION_CLEAR,
      [ContextKey.Product]: null,
      pagination: null,
    },
    { ...globalContextFields }
  );

  // snowplow event for product_listing
  trackSnowplow(
    {
      event: EVENT_NAMES.PRODUCT_LISTING,
      [ContextKey.Product]: getProductsArrayContextData(context),
      [ContextKey.Pagination]: getPaginationContextData(context),
    },
    { ...globalContextFields }
  );
};

export const trackSort = (context: any, globalContextFields: IGlobalContextFields) => {
  const filterSortData = {
    filter_by: null,
    sort_by: context.sortEventValue.replace(':', '').toLowerCase().replaceAll(' ', '-'),
  };

  if (!hasExistingEvent(EventName.ApplyFilter, 'filter_sort', filterSortData)) {
    trackSnowplow(
      {
        event: EVENT_NAMES.FILTER_SORT_CLEAR,
        [ContextKey.FilterSort]: null,
      },
      { ...globalContextFields }
    );

    trackSnowplow(
      {
        event: EventName.ApplySort,
        [ContextKey.FilterSort]: filterSortData,
      },
      { ...globalContextFields }
    );
  }
};

export const trackFilter = (context: any, globalContextFields: IGlobalContextFields) => {
  const formattedFilters = context.trackFilterData.map((data: { category: string; value: string }) => ({
    category: normalizeStringValueForSnowplow(data.category),
    value: normalizeStringValueForSnowplow(data.value),
  }));
  //filter_sort_clear event is pushed to analyticsLayer to null any previous parameter
  trackSnowplow(
    {
      event: EVENT_NAMES.FILTER_SORT_CLEAR,
      [ContextKey.FilterSort]: null,
      custom_engagement: null,
    },
    { ...globalContextFields }
  );

  trackSnowplow(
    {
      event: EventName.ApplyFilter,
      [ContextKey.FilterSort]: {
        filter_by: formattedFilters,
        sort_by: null,
      },
    },

    { ...globalContextFields }
  );
};

export const trackEvent = (type: string, context: any, snowplowGlobalContextFields: IGlobalContextFields) => {
  switch (type) {
    case HELIOS_ADD_TO_CART:
      trackCartChanges(context, snowplowGlobalContextFields);
      break;
    case HELIOS_PRODUCT_CAROUSEL:
      trackProductCarousel(context, snowplowGlobalContextFields);
      break;
    case HELIOS_PRODUCT_EVENT:
      trackProduct(context, snowplowGlobalContextFields);
      break;
    case HELIOS_AD_TRACKING:
    case PRODUCT_CLICK:
      trackAdEvents(context, snowplowGlobalContextFields);
      break;
    case HELIOS_NAV_CLICK:
      trackNavClicks(context, snowplowGlobalContextFields);
      break;
    case HELIOS_PAGE_TRACKING:
      trackPage(context, snowplowGlobalContextFields);
      break;
    case HELIOS_SORT_TRACKING:
      trackSort(context, snowplowGlobalContextFields);
      break;
    case HELIOS_FILTER_TRACKING:
      trackFilter(context, snowplowGlobalContextFields);
      break;
    default:
      return;
  }
};

export const trackRender = (componentName: string) => {
  switch (componentName) {
    case HELIOS_REFRESH_LINKS_CLICK_TRACKING:
      snowplow?.refreshLinkClickTracking();
      break;
    default:
      return;
  }
};
